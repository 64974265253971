<template>
  <v-container id="user" fluid tag="section">
    <v-snackbar v-model="updateRegisterDriverTemplate" :timeout="4000" top>
      <span>Register Driver Email Templates Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="updateRegisterDriverTemplate = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-card>
      <v-card-title> <h3>Register Driver Email Templates</h3> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="registerDriverEmail">
              <v-container class="py-2">
                <vue-editor v-model="registerDriverEmailTemplate"></vue-editor>
                <p style="color: red" v-if="showRegisterDriverError">
                  Register Driver Email Templates is required
                </p>
                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mt-3"
                      @click="submitRegisterDriverEmail"
                      :loading="loading"
                      >Update
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "RegisterDriverEmail",
  components: { VueEditor },
  data() {
    return {
      loading: false,
      registerDriverEmailTemplate: "",
      showRegisterDriverError: false,
      loading: false,
      registerDriverEmailID: "",
      updateRegisterDriverTemplate: false,
    };
  },
  methods: {
    getRegisterDriverEmail() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "setting/edit/register_driver_email_template"
        )
        .then((response) => {
          if (response.status == 200) {
            this.registerDriverEmailID = response.data.setting._id;
            this.registerDriverEmailTemplate = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitRegisterDriverEmail() {
      if (this.registerDriverEmailTemplate != "") {
        this.showRegisterDriverError = false;
        this.loading = true;
        let data = {
          id: this.registerDriverEmailID,
          type: "register_driver_email_template",
          value: this.registerDriverEmailTemplate,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.updateRegisterDriverTemplate = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.showRegisterDriverError = true;
      }
    },
  },
  mounted() {
    this.getRegisterDriverEmail();
  },
};
</script>